import useEnv from "../../../hooks/useEnv";

import styles from "./FooterGea.module.css";

const FooterGea = ({ agency }) => {
  const { geconturUrl } = useEnv();

  return (
    <div class="flex">
      <div class="w-1/2 pl-2">
        <img
          className={styles.Image}
          alt="logo"
          height="62"
          src={`${geconturUrl}/img/agencies/${agency?.logo_path}`}
        />
      </div>

      <div class="ml-auto">
        <div className={styles.Address}>
          {agency?.social_denomination}<br />
          {agency?.address}
          <br />
          {agency?.postal_code} {agency?.locale}
          <br />
          <br />

          RNAVT {agency?.RNAVT}
        </div>
      </div>
    </div>
  )
};

export default FooterGea;
