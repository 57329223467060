import React, { forwardRef } from "react";
import FooterGea from "./FooterGea";
import { isFirefox } from "react-device-detect";

import styles from "./ComponentToPrintGea.module.css";

const ComponentToPrintGea = forwardRef(({ offer, agency }, ref) => {
  const {
    AccommodationName,
    OfferTitle,
    Description,
    LeadInPrice,
    PriceType,
    ImageURL,
    WhatsIncluded,
    Nights,
  } = offer;

  const agencyColor = agency.color || '#2FAB67'

  return (
    <div
      ref={ref}
      className="p-8 bg-white"
      style={{ height: isFirefox ? "1050px" : "1120px" }}
    >
      <div
        className="bg-white h-full"
        style={{ position: "relative", fontFamily: "Helvetica" }}
      >
        <div style={{background: agencyColor}} className={styles.Title}>{OfferTitle}</div>
        <div
          className={styles.Capa}
          style={{
            backgroundSize: "cover",
            height: "380px",
            backgroundImage: `url(${ImageURL})`,
          }}
        ></div>

        <div className={styles.Hotel}>{AccommodationName}</div>

        <div class="flex">
            <div class="flex-60 w-3/5 pr-10">
              <div className={styles.Noites}>{Nights && `${Nights} Noites`}</div>
              <div className={styles.Description}>{Description}</div>
            </div>
            <div class="flex-40 w-2/5">    

              <div class="flex justify-center gap-x-2 pt-2">
                <div class="flex-60 w-1/8">
                  <div className={styles.Desde}>{PriceType}</div>
                  <div className={styles.Moeda} style={{color: agencyColor}}>€</div>
                </div>
                <div class="flex-40 w-7/8">    
                  <div className={styles.Price} style={{color: agencyColor}}>
                    {LeadInPrice} 
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div id={styles.Footer}>
          <div className={styles.Incluido}>{WhatsIncluded}</div>
          <hr style={{ margin: "20px 0px", border: '1px solid #979797', opacity: '.50' }} />
          <FooterGea agency={agency} />
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
});

export default ComponentToPrintGea;
